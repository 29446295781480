//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";

// import Processfield from "./components/Processfield.vue";

export default {
  name: "Home",
  data() {
    return {
      saveSate: false,
      ctfrom: true,
      outOB: {
        hhrDeptName: "",
      },
      activeName: "3",
      enteryData: {},
      actionList: {
        CarbonCopy: {
          text: "抄送",
          color: "orange",
        },
        Approved: {
          text: "同意",
          color: "green",
        },
        Jump: {
          text: "驳回",
          color: "deep-orange",
        },
      },
      copyVal: [],
      pageData: null,
      active: 0,

      approveData: {
        value: "",
        columns: [],
        pickStatus: false,
        searchValue: "",
      },
      jumpListData: {
        value: "",
        columns: [],
        pickStatus: false,
      },
      approveAdviceValue: "",
      copyArr: [],
      copyString: "",
      processNode: 0,
      formDisable: false,
      hhrBiogData: {},
      rateData: {
        zrx: {
          hhrRuleCode: 10,
          hhrGuidanceScore: 0,
          hhrScore: 10,
          hhrRuleVal: "对自身岗位职责与目标负责，勇于承担责任",
          title: "责任心（10分）",
        },
        zdx: {
          hhrRuleCode: 20,
          hhrGuidanceScore: 0,
          hhrScore: 10,
          hhrRuleVal: "积极推进工作，努力寻求资源，不回避困难",
          title: "主动性（10分）",
        },
        tdys: {
          hhrRuleCode: 30,
          hhrGuidanceScore: 0,
          hhrScore: 10,
          hhrRuleVal: "积极推进工作，努力寻求资源，不回避困难",
          title: "团队意识（10分）",
        },
        khys: {
          hhrRuleCode: 40,
          hhrGuidanceScore: 0,
          hhrScore: 10,
          hhrRuleVal: "积极关注客户需求，主动为客户解决问题",
          title: "客户意识（10分）",
        },
        xxlw: {
          hhrRuleCode: 50,
          hhrGuidanceScore: 0,
          hhrScore: 10,
          hhrRuleVal: "善于总结，学习，正确理解工作目标，不出现相同错误",
          title: "学习领悟（10分）",
        },
        sgcd: {
          hhrRuleCode: 60,
          hhrGuidanceScore: 0,
          hhrScore: 20,
          hhrRuleVal: "相关知识、经验、能力和技能与岗位的符合程度",
          title: "适岗程度（20分）",
        },
        gzxl: {
          hhrRuleCode: 70,
          hhrGuidanceScore: 0,
          hhrScore: 10,
          hhrRuleVal: "在规定的时间完成工作任务，遇到问题迅速反应",
          title: "工作效率（10分）",
        },
        gzzl: {
          hhrRuleCode: 80,
          hhrGuidanceScore: 0,
          hhrScore: 10,
          hhrRuleVal: "完成的工作负荷要求，达到预期的效果",
          title: "工作质量（10分）",
        },
        pxzk: {
          hhrRuleCode: 90,
          hhrGuidanceScore: 0,
          hhrScore: 10,
          hhrRuleVal:
            "参加入职培训的表现和成绩，培训成绩对应分值：A(8-10)分、B(6-8)分、C(4-6)分、D(2-4)分、E(0-2)分",
          title: "培训状况（10分）",
        },
      },
      scoreStaus: true,
    };
  },
  created() {
    window.document.title = "OAdemo";
    this.onLoad();
    // this.getInitData();
  },
  computed: {
    zf() {
      return (
        this.rateData.zrx.hhrGuidanceScore +
        this.rateData.zdx.hhrGuidanceScore +
        this.rateData.tdys.hhrGuidanceScore +
        this.rateData.khys.hhrGuidanceScore +
        this.rateData.xxlw.hhrGuidanceScore +
        this.rateData.sgcd.hhrGuidanceScore +
        this.rateData.gzxl.hhrGuidanceScore +
        this.rateData.gzzl.hhrGuidanceScore +
        this.rateData.pxzk.hhrGuidanceScore
      );
    },
    dj() {
      let v = this.zf;
      if (v < 30) {
        return "E";
      } else if (v < 50) {
        return "D";
      } else if (v < 70) {
        return "C";
      } else if (v < 85) {
        return "B";
      } else {
        return "A";
      }
    },
  },
  mounted() {
    window.document.title = "OA流程";
    //this.wxConfig();
  },
  methods: {
    getInitData() {
      console.log("init");
    },
    //保存指导人信息
    saveGuidanceInfoClick() {
      this.enteryData._status = "update";
      this.enteryData.hhrSummryLevel = this.dj;
      this.enteryData.hhrSummaryScore = this.zf;
      let postData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          reqType: "save",
          entityName:
            "com.boogoo.hhr.corehr.wf.domain.entity.WfOrgPerProbationPeriod",
        },
        payload: JSON.stringify([this.enteryData]),
      };
      console.log("this.enteryData", this.enteryData);

      this.saveSate = true;
      this.axios
        .post("/boogooForm/excuteMethod", postData, {
          headers: {
            processDefinitionKey: "PER_QUALIFIED_NEW_DEF",
            employeeNum: this.$memberInfo.hhrEmpid,
          },
        })
        .then((res) => {
          console.log(res);

          if (res && res.data) {
            this.saveSate = false;
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              if (resultData.failed) {
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.$q.notify({
                  type: "positive",
                  color: "grey-8",
                  textColor: "white",
                  position: "center",
                  message: "保存成功",
                  timeout: 1000,
                });
                 this.loadFormData();
               
              }
            }
          }
        })
        .catch(() => {
          this.saveSate = false;
          Toast("请求出错了");
        });
    },
    saveScoreClick() {
      let payloadData = [];
      if (this.scoreStaus == true) {
        for (var keya in this.rateData) {
          payloadData.push({
            _status: "create",
            hhrEmpid: this.enteryData.hhrEmpid,
            ppid: this.enteryData.id,
            hhrBusinessKey: this.enteryData.hhrBusinessKey,
            hhrGuidanceScore: this.rateData[keya].hhrGuidanceScore,
            hhrRuleCode: this.rateData[keya].hhrRuleCode,
            hhrRuleVal: this.rateData[keya].hhrRuleVal,
            hhrScore: this.rateData[keya].hhrScore,
            hhrSortNum: this.rateData[keya].hhrRuleCode,
          });
        }
      } else {
        for (var key in this.rateData) {
          payloadData.push({
            _status: "update",
            _token:this.rateData[key].oriData._token,
            id:this.rateData[key].oriData.id,
            hhrEmpid: this.enteryData.hhrEmpid,
            ppid: this.enteryData.id,
            hhrBusinessKey: this.enteryData.hhrBusinessKey,
            hhrGuidanceScore: this.rateData[key].hhrGuidanceScore,
            hhrRuleCode: this.rateData[key].hhrRuleCode,
            hhrRuleVal: this.rateData[key].hhrRuleVal,
            hhrScore: this.rateData[key].hhrScore,
            hhrSortNum: this.rateData[key].hhrRuleCode,
            tenantId:0,
            objectVersionNumber:this.rateData[key].oriData.objectVersionNumber

          });
        }
      }

      let postData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          reqType: "save",
          entityName:
            "com.boogoo.hhr.corehr.wf.domain.entity.WfOrgPerScoreInstance",
        },
        payload: JSON.stringify(payloadData),
      };

      this.saveSate = true;
      this.saveGuidanceInfoClick();
      this.axios
        .post("/boogooForm/excuteMethod", postData, {
          headers: {
            processDefinitionKey: "PER_QUALIFIED_NEW_DEF",
            employeeNum: this.$memberInfo.hhrEmpid,
          },
        })
        .then((res) => {
          console.log(res);

          if (res && res.data) {
            this.saveSate = false;
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              if (resultData.failed) {
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.$q.notify({
                  type: "positive",
                  color: "grey-8",
                  textColor: "white",
                  position: "center",
                  message: "保存成功",
                  timeout: 1000,
                });
                 this.loadScoreData();
              }
            }
          }
        })
        .catch(() => {
          this.saveSate = false;
          Toast("请求出错了");
        });
    },
    onSubmit() {
      let obj = {
        approveAdviceValue: "Approved",
        jumpTarget: null,
        jumpTargetName: null,
        action: "complete",
        variablesValue: "Approved",
      };
      let payload = {
        comment: this.approveAdviceValue,
        variables: [
          {
            name: "approveResult",
            value: obj.variablesValue,
          },
          {
            name: "comment",
            value: this.approveAdviceValue,
          },
        ],
        carbonCopyUsers: this.copyString,
        assignee: null,
        action: obj.action,
        jumpTarget: obj.jumpTarget,
        jumpTargetName: obj.jumpTargetName,
        attachmentUuid: null,
        currentTaskId: this.pageData.processInstanceId,
      };
      this.doprocess(payload);
    },
    onRejected() {
      if (this.approveAdviceValue == "") {
        this.$q.notify({
          color: "red-5",
          textColor: "white",
          icon: "warning",
          position: "center",
          message: "请填写拒绝原因",
          timeout: 1000,
        });
        return;
      }
      let obj = {
        approveAdviceValue: "Rejected",
        jumpTarget: null,
        jumpTargetName: null,
        action: "complete",
        variablesValue: "Approved",
      };
      let payload = {
        comment: this.approveAdviceValue,
        variables: [
          {
            name: "approveResult",
            value: obj.variablesValue,
          },
          {
            name: "comment",
            value: this.approveAdviceValue,
          },
        ],
        carbonCopyUsers: this.copyString,
        assignee: null,
        action: obj.action,
        jumpTarget: obj.jumpTarget,
        jumpTargetName: obj.jumpTargetName,
        attachmentUuid: null,
        currentTaskId: this.pageData.processInstanceId,
      };
      this.doprocess(payload);
    },
    onJumpBack() {
      if (this.approveAdviceValue == "") {
        this.$q.notify({
          color: "red-5",
          textColor: "white",
          icon: "warning",
          position: "center",
          message: "请填写驳回原因",
          timeout: 1000,
        });
        return;
      }
      if (this.jumpListData.columns.length > 0) {
        this.jumpListData.pickStatus = true;
        return;
      } else {
        Toast.loading({
          message: "数据加载中...",
          duration: 0,
        });
        let taskId = this.$route.query.id;
        let postData = {
          pathVariableMap: {
            organizationId: "0",
            taskId: taskId,
          },
          headerParamMap: {},
          requestParamMap: {},
          payload: "",
        };

        this.axios
          .post("/boogoo/getJumpList", postData)
          .then((res) => {
            Toast.clear();
            if (res && res.data) {
              if (res.data.status == "200") {
                let resultData = JSON.parse(res.data.payload);
                console.log(resultData);
                if (!resultData.failed) {
                  for (let i = 0; i < resultData.length; i++) {
                    this.jumpListData.columns.push({
                      text: resultData[i].actName,
                      value: resultData[i].actId,
                    });
                  }

                  this.jumpListData.pickStatus = true;
                } else {
                  this.$q.notify({
                    color: "red-5",
                    textColor: "white",
                    icon: "warning",
                    position: "center",
                    message: resultData.message,
                    timeout: 1000,
                  });
                }
                // this.pageData = resultData;
                // this.list = resultData.content;
              } else {
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: "出错了",
                  timeout: 1000,
                });
              }
            }
          })
          .catch(() => {
            Toast.clear();
            Toast("请求出错了");
          });
      }
    },
    doprocess(payload) {
      Toast.loading({
        message: "正在请求...",
        duration: 0,
      });

      let postData = {
        pathVariableMap: {
          organizationId: "0",
          taskId: this.$route.query.id,
        },
        headerParamMap: {},
        requestParamMap: {
          employeeCode: this.$memberInfo.hhrEmpid, //当前登录人员工ID 必填
        },
        payload: JSON.stringify(payload),
      };
      this.axios
        .post("/boogoo/executeTaskActionV2", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "204") {
              this.doCallback();
            }
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    onLoad() {
      let taskId = this.$route.query.id;
      Toast.loading({
        message: "数据加载中...",
        duration: 0,
      });

      let postData = {
        pathVariableMap: {
          organizationId: "0",
          processInstanceId: taskId, //当前流程编号
        },
        headerParamMap: {},
        requestParamMap: {
          employeeCode: this.$memberInfo.hhrEmpid, //员工ID
           "type":this.$route.query.type
        },
        payload: "",
      };
      this.axios
        .post("/boogoo/instanceDetailV2", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              console.log("queryTaskV2", resultData);
              if (resultData.failed) {
                this.saveSate = false;
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.pageData = resultData;
                       this.formDisable = false;
                       this.processNode = 2;
                            this.ctfrom = true;
                              this.activeName = "3";

                // console.log("1", this.processNode);
                this.loadFormData();
                this.loadScoreData();
              }

              // this.list = resultData.content;
            }
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    loadFormData() {
      let payload = [{ hhrBusinessKey: this.pageData.businessKey }];
      let postData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          reqType: "query",
          entityName:
            "com.boogoo.hhr.corehr.wf.domain.entity.WfOrgPerProbationPeriod",
        },
        payload: JSON.stringify(payload),
      };
      this.axios
        .post("/boogooForm/selectMethod", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              console.log("selectMethod", resultData);
              if (resultData.failed) {
                this.saveSate = false;
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.enteryData = resultData.content[0];
                console.log("this.enteryData", this.enteryData);
                this.loadEmpData();
              }

              // this.list = resultData.content;
            }
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    loadScoreData() {
      let payload = [{ hhrBusinessKey: this.pageData.businessKey }];
      let postData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          reqType: "query",
          entityName:
            "com.boogoo.hhr.corehr.wf.domain.entity.WfOrgPerScoreInstance",
        },
        payload: JSON.stringify(payload),
      };
      this.axios
        .post("/boogooForm/selectMethod", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              console.log("loadScoreData", resultData);
              if (resultData.failed) {
                this.saveSate = false;
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.scoreStaus = resultData.empty;
                this.matchScore(resultData.content);
                // this.enteryData = resultData.content[0];
                //  console.log("loadScoreData", this.enteryData);
                // this.loadEmpData();
              }

              // this.list = resultData.content;
            }
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    matchScore(content) {
      let scoreKey = {
        10: "zrx",
        20: "zdx",
        30: "tdys",
        40: "khys",
        50: "xxlw",
        60: "sgcd",
        70: "gzxl",
        80: "gzzl",
        90: "pxzk",
      };
      for (let i = 0; i < content.length; i++) {
        this.rateData[scoreKey[content[i].hhrRuleCode]] = {
          hhrRuleCode: content[i].hhrRuleCode,
          hhrGuidanceScore: Number(content[i].hhrGuidanceScore),
          hhrScore: content[i].hhrScore,
          hhrRuleVal: content[i].hhrRuleVal,
          oriData: content[i],
        };
      }
    },
    loadEmpData() {
      let hhrpostData = {
        pathVariableMap: {
          organizationId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          hhrEmpid: this.enteryData.hhrEmpid,
        },
        payload: "{}",
      };
      this.axios
        .post("/boogoo/personInfoByhhrEmpidPost ", hhrpostData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              if (resultData.failed) {
                this.saveSate = false;
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.hhrBiogData = resultData;
                console.log("hhrBiogData", this.hhrBiogData);
              }

              // this.list = resultData.content;
            }
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("boogoo/personInfoByhhrEmpidPost请求出错了");
        });
    },

    popConfirm(obj) {
      switch (obj.key) {
        case "hhrPosnCode":
          this.outOB.hhrDeptName = obj.value.obj.hhrDeptName;
          this.enteryData.hhrDeptCode = obj.value.obj.hhrDeptCode;
          break;
      }
      this.enteryData[obj.key] = obj.value.value;
      // console.log(obj.key,this[obj.key]);
      console.log(this.enteryData);
    },
    dateConfirm(obj) {
      this.enteryData[obj.key] = obj.value;
      console.log(this.hhrBiogData);
    },
    jumpListConfirm(obj) {
      console.log(obj);
      let payload = {
        assignee: "",
        action: "jump",
        comment: this.approveAdviceValue,
        jumpTarget: obj.value,
        jumpTargetName: obj.name,
        currentTaskId: this.$route.query.id,
      };
      this.doprocess(payload);
    },
    doCallback() {
      this.$q.notify({
        color: "primary",
        textColor: "white",
        type: "positive",
        position: "center",
        message: "操作成功",
        timeout: 1500,
      });
      this.$router.replace("/oaplatform/list");
    },
    confirmCopy(obj) {
      this.copyArr = obj;
      let tempCopy = [];
      for (let i = 0; i < this.copyArr.length; i++) {
        tempCopy.push(this.copyArr[i].value);
      }
      this.copyString = tempCopy.toString();
      console.log("confirmCopy", this.copyArr);
      console.log("copyString", this.copyString);
    },
  },
};
